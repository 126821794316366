import { ILayout, IRestaurant, SplitModeTypeEnum } from '@/types';

export enum BillStatusEnum {
  partiallyPaid = 'partiallyPaid',
  fullyPaid = 'fullyPaid',
}

export enum RecordStatusEnum {
  accepted = 'accepted',
  pending = 'pending',
  failed = 'failed',
}

export interface IGetPaymentDetailPayload {
  restaurantUnique: string;
  tableID: string;
  id: string;
  diningSessionID: string;
  forceFresh: boolean;
  cc: string;
  f1?: string;
  f2?: string;
  hash?: string;
  jwt?: string;
  fetchMode?: string;
}

export interface IGetReceiptPayload {
  dsi: string;
  pri: string;
}

export interface IGetLayoutConfig {
  cc: string;
  vendor?: IRestaurant;
}

export interface IGetPaymentDetailConfig extends IGetLayoutConfig {
  ignoreCache?: boolean;
  qsr?: boolean;
}

export interface IKeyValue {
  key: string;
  value: any;
  layout?: ILayout;
  order?: number;
  title?: string;
  id?: string;
  sig?: string;
}

export interface IKeyValueOrder extends IKeyValue {
  qty?: string;
  altValue?: string;
  secondAltValue?: string;
  updatedAt?: number;
  selectedItemsQty?: number;
  paidItemsQty?: number;
}

export enum AdditiveCategoryEnum {
  Tax = 'tax',
  Discount = 'discount',
  Fee = 'fee',
  Sum = 'sum',
}

export interface IAdditiveView {
  key: string;
  amount: string;
  value: string;
  category: AdditiveCategoryEnum;
  title: string;
}

export interface IToppingView {
  qty: string;
  title: string;
  unitPrice: string;
  updatedAt: number;
  subTotal?: string;
  finalPrice?: string;
}

export interface IOrderItemView {
  qty: string;
  title: string;
  unitPrice: string;
  updatedAt: number;
  subTotal?: string;
  finalPrice?: string;
  finalUnitPrice?: string;
  toppings?: Array<IToppingView>;
  additives?: Array<IAdditiveView>;
  sig: string;
}

export interface ISetAmountRequestPayload {
  amount: string | null;
  diningSessionID: string;
  id: string;
  name: string;
  splitMode?: string;
  share?: number;
  totalShares?: number;
  items?: IPaidItem[];
}

export interface ISetAmountV2StringRequestPayload {
  amount: string | null;
  diningSessionID: string;
  id: string;
  name: string;
  split: {
    mode?: string;
    meta: any;
    itemsToPay: IPaidItemString[];
    shareToPay: {
      share?: string;
      total?: string;
    };
  };
}

export interface IPaidItem {
  id?: string;
  index: number;
  qty: number;
  sig: string;
}

export interface IPaidItemString {
  id?: string;
  index: number;
  qty: string;
  sig: string;
}

export interface ISetAmountStringRequestPayload {
  amount: string | null;
  diningSessionID: string;
  id: string;
  name: string;
  splitMode?: string;
  share?: string;
  totalShares?: string;
  items?: IPaidItemString[];
}

export interface IPartySplit {
  items?: Array<IPaidItemString>;
  meta?: any;
  splitMode: SplitModeTypeEnum;
  share: string;
  totalShares: string;
}

export interface IPaymentParty {
  amount: string;
  id: string;
  name: string;
  paymentDone?: boolean;
  paidAt?: number;
  nonQlub?: boolean;
  billSplit: IPartySplit;
}

export interface IPartySplitUI {
  items?: Array<IPaidItem>;
  meta?: any;
  splitMode: SplitModeTypeEnum;
  share: number;
  totalShares: number;
}

export interface IPaymentPartyUI {
  amount: string;
  amountNumber: number;
  id: string;
  name: string;
  paymentDone?: boolean;
  paidAt?: number;
  nonQlub?: boolean;
  billSplit: IPartySplitUI;
}

interface IPaymentDetailsBill {
  qlubDiscount: string;
  paid: string;
  remaining: string;
  subTotal: string;
  tax: string;
  total: string;
  vat: string;
  yourShare: string;
  yourCommission: string;
  remainingCommission: string;
  additives?: Array<IAdditiveView>;
  billAmount?: string;
  payableAmount?: string;
  updatedAt: string;
}

export interface IPaymentDetailsBillNumber {
  qlubDiscount: number;
  paid: number;
  remaining: number;
  subTotal: number;
  tax: number;
  total: number;
  vat: number;
  yourShare: number;
  billAmount: number;
  payableAmount: number;
  voucherAmount: number;
}

export interface IPaymentDetailsOrderItems {
  item: IKeyValueOrder;
  items: IKeyValueOrder[];
}

export interface IPaymentSplit {
  availableModes: Array<SplitModeTypeEnum>;
  mode: SplitModeTypeEnum;
  totalShares: string;
  shareAmount: string;
}

export interface IVoucher {
  code: string;
  partyID: string;
  redeemed: boolean;
  isVoucherOwner?: boolean;
  amount?: string;
}

export interface IPaymentDetailsResponse {
  bill: IPaymentDetailsBill;
  billSplit: IPaymentSplit;
  currency: string;
  currencyPrecision: number;
  orderItems?: IOrderItemView[];
  parties?: IPaymentParty[];
  restaurantUnique: string;
  tableID: string;
  diningSessionID: string;
  tableName: string;
  jwt?: string;
  campaign: {
    appliedVouchers: IVoucher[];
  };
}

export interface IPaymentSplitUI extends IPaymentSplit {
  totalSharesNumber: number;
  shareAmountNumber: number;
  conflict?: boolean;
}

export enum CampaignVoucherStateEnum {
  Hidden = 'hidden',
  Used = 'used',
  Visible = 'visible',
}

export interface IPromoCode {
  isPromoDisabled: boolean;
  splitByOtherNotPaid: boolean;
  preventOpenModal: boolean;
  disableSplitButton: boolean;
}

export interface IPaymentUserInfoLegacy {
  email?: string;
  phone?: string;
}

export interface IPaymentUserInfo {
  birthDate: null | string;
  email: null | string;
  emailVerified: boolean;
  id: string;
  isGuest: boolean;
  name: null | string;
  phone: null | string;
  phoneVerified: boolean;
}

export enum LinkStatus {
  CREATED = 'created',
  DISCARDED = 'discarded',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
}

export interface IPaymentDetailsLegacy {
  JWTToken?: string;
  bill: IPaymentDetailsBill;
  currency: string;
  currencyPrecision: number;
  description?: string;
  diningSessionID: string;
  expiration: number;
  parties?: IPaymentPartyUI[];
  reference?: string;
  restaurantUnique: string;
  userInfo?: IPaymentUserInfoLegacy;
  sequentialID?: string;
  jwt?: string;
  status: LinkStatus;
  expired: boolean;
  isMultiUse: boolean;
}

export interface IPaymentDetails {
  JWTToken?: string;
  bill: IPaymentDetailsBill;
  billSplit?: IPaymentSplit;
  campaign?: {
    appliedVouchers: IVoucher[];
  };
  currency: string;
  currencyPrecision: number;
  diningSessionID: string;
  meta: IPaymentMeta;
  parties?: IPaymentPartyUI[];
  restaurantUnique: string;
  staff?: null | string;
  tableID?: string;
  tableName?: string;
  tablePreferredName?: string;
  jwt?: string;
}

export interface IPaymentMeta {
  description?: string;
  expiration: number;
  expired: boolean;
  isMultiUse: boolean;
  reference?: string;
  sequentialID: string;
  status: LinkStatus;
  userInfo: IPaymentUserInfo;
}

export interface ISetAmountV2Response {
  paymentDetails: IPaymentDetailsResponse;
  error?: { items: string };
}

export interface IRateRequestPayload {
  diningSessionID: string;
  id: string;
  rate: number;
  reference: string;
}

export interface IEmailReceiptRequestPayload {
  diningSessionID: string;
  email: string;
  id: string;
  reference: string;
  lang: string;
  countryCode: string;
}

export interface IEmailReceiptRequestNewPayload {
  countryCode: string;
  diningSessionID: string;
  email?: string;
  phone?: string;
  id: string;
  lang: string;
  reference: string;
  timezone: string;
}

export interface IEmailReceiptResponse {
  status?: string;
  meta: { como: string };
  message: string;
}

export interface IPaymentStatusPayload {
  diningSessionID: string;
  reference: string;
  id: string;
}

export interface IPaymentStatus {
  amount: string;
  currency: string;
  bill: {
    currency: string;
    orderID: string;
    remainingAmount: string;
    status: BillStatusEnum;
    tableID: string;
    tableName: string;
    totalAmount: string;
  };
  campaign: {
    appliedVouchers: [
      {
        amount: string;
        code: string;
        partyID: string;
        redeemed: boolean;
      },
    ];
    vouchers: [
      {
        amount: string;
        code: string;
        partyID: string;
        redeemed: boolean;
      },
    ];
  };
  record: {
    accepted: boolean;
    billAmount: string;
    createdAt: BigInt64Array;
    gatewayVendor: string;
    reference: string;
    status: RecordStatusEnum;
    tipAmount: string;
    total: string;
  };
  verified: boolean;
}

export interface IPaymentStatusClient extends IPaymentStatus {
  activeCampaign?: IVoucher;
}

export interface IUpdatePaymentDetails {
  details?: IPaymentDetailsResponse;
  yourTotal: string;
}

export interface IAddPartPayload {
  country: string;
  restaurantUnique: string;
  diningSessionID: string;
  id: string;
  jwt?: string;
}

export interface IPaymentDetailsCache {
  time: number;
  details: IPaymentDetails;
  force: boolean;
}

export interface ILockPayment {
  diningSessionID: string;
  id: string;
  gatewayID: string;
}

export interface IPaymentLockResponse {
  sessions: Array<{
    reference: string;
    status: string;
    timestamp: number;
    tipAmount: string;
    billAmount: string;
    gatewayVendor: string;
  }>;
  state: 'acquired' | 'failedPartial' | 'failedFull';
}

export interface IUnlockPayment {
  diningSessionID: string;
  id: string;
  gatewayID: string;
}

export interface IPaymentUnlockResponse {
  result: boolean;
}

export interface IVoucherValidatePayload {
  code: string | null;
  diningSessionID: string;
  id: string;
}

export interface IPreferredCurrency {
  code: string;
  forex: number;
  tempCalculatedAmountWithoutTip: number; // this will be removed with exact implementation
}

export interface IGetPaymentLinkPayload {
  cc: string;
  restaurantUnique: string;
  orderId: string;
  f1?: string;
  f2?: string;
  hash?: string;
  diningSessionID?: string;
  legacy?: boolean;
}

export type PaymentLinkStatusEffectProps = {
  reference: string | undefined;
  enabled?: boolean;
};

export type BillSendPayload = {
  dsi: string;
  id: string;
  email: string;
  reference: string;
  cc: string;
  lang: string;
  tz?: string;
};

export type SaveCustomerDataPayload = {
  dsi: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  reference: string;
};

export type PaymentLinkEffectProps = {
  shouldUpdateStatus?: boolean;
  shouldUpdateLoader?: boolean;
};

export enum PaymentElement {
  stcpay = 'stcpay',
}
