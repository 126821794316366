import { CalculateTipProps, IPaymentDetails, TipModeEnum } from '@/types';

export const calculateTip = ({
  remaining,
  selectedTip,
  tipMode,
  customTip,
  isTipEnabled,
}: CalculateTipProps) => {
  if (!isTipEnabled) return 0;
  const amount = Number(remaining ?? 0);
  let tip = 0;
  if (selectedTip !== undefined) {
    if (
      tipMode === TipModeEnum.Percentage ||
      tipMode === TipModeEnum.PercentageWithHint
    )
      tip = Number(amount) * (selectedTip / 100);
    else tip = selectedTip;
  } else tip = Number(customTip);

  return tip;
};

export const calculateTotalAmounts = ({
  currencyPrecision,
  ...props
}: CalculateTipProps & { currencyPrecision: number | undefined }) => {
  const tip = props.isTipEnabled ? calculateTip(props) : 0;
  const amount = Number(props.remaining ?? 0);
  return {
    total: Number(amount + tip).toFixed(currencyPrecision ?? 2),
    tip: tip.toFixed(currencyPrecision ?? 2),
  };
};

export function transformLink(
  data: any,
  legacy: boolean = true,
): IPaymentDetails {
  if (legacy) {
    return {
      JWTToken: data?.JWTToken,
      bill: data?.bill,
      currency: data?.currency,
      currencyPrecision: data?.currencyPrecision,
      diningSessionID: data?.diningSessionID,
      meta: {
        description: data?.description,
        expiration: data?.expiration,
        expired: data?.expired,
        isMultiUse: data?.isMultiUse,
        reference: data?.reference,
        sequentialID: data?.sequentialID,
        status: data?.status,
        userInfo: data?.userInfo,
      },
      parties: data?.parties,
      restaurantUnique: data?.restaurantUnique,
    };
  } else return { ...data, jwt: data?.JWTToken };
}
